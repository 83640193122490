import Vue from "vue";
import App from "./App.vue";
import store from "./store";

import "./main.css";

Vue.config.productionTip = false;

import VueFriendlyIframe from "vue-friendly-iframe";
Vue.use(VueFriendlyIframe);

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
